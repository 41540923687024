<template>
  <CSidebar
    :minimize="minimize"
    unfoldable
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <CIcon
        class="c-sidebar-brand-full"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 630.11 174.77"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 630.11 174.77"
      />
    </CSidebarBrand>
    <CRenderFunction flat :contentToRender="nav()" />
    <CSidebarMinimizer
      class="c-d-md-down-none"
      @click.native="$store.commit('toggle', 'sidebarMinimize')"
    />
  </CSidebar>
</template>

<script>
import nav from "./_nav";
export default {
  name: "TheSidebar",

  methods: {
    nav: function() {
      let projetoID = this.$route.params.projetoID;
      let objetoNavegacao = JSON.parse(JSON.stringify(nav[0]));
      if (projetoID !== undefined) {
        objetoNavegacao["_children"].forEach(element => {
          if (element.to !== undefined) {
            element.to = element.to.replace(":id", projetoID);
          }
        });
        return [objetoNavegacao];
      }
      return nav;
    }
  },
  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    }
  }
};
</script>

