export default [{
  _name: 'CSidebarNav',
  _children: [
    {
      _name: 'CSidebarNavItem',
      name: 'Projetos',
      to: '/',
      icon: 'cil-library'
    },
    {
      _name: 'CSidebarNavTitle',
      _children: ['Projeto']
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Dados',
      to: '/projeto/:id/dados',
      icon: 'cil-location-pin'
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Sondagem',
      to: '/projeto/:id/sondagem',
      icon: 'cil-sort-descending'
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Pilares',
      to: '/projeto/:id/pilar',
      icon: 'cil-square'
    },

    {
      _name: 'CSidebarNavTitle',
      _children: ['Parâmetros']
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Critérios',
      to: '/projeto/:id/criterio',
      icon: 'cil-settings',
    },
    {
      _name: 'CSidebarNavTitle',
      _children: ['Dimensionamentos']
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Geotécnicos',
      to: '/projeto/:id/geotecnico',
      icon: 'cil-vertical-align-bottom',
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Estrutural',
      to: '/projeto/:id/estrutural',
      icon: 'cil-line-style'
    },
  ]
}]