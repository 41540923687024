<template>
  <CHeader with-subheader>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
      v-if="this.$route.params.projetoID"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
      v-if="this.$route.params.projetoID"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <CIcon name="logo" height="48" alt="Logo" viewBox="0 0 630.11 174.77" />
    </CHeaderBrand>
    <CHeaderBrand to="/" v-if="!this.$route.params.projetoID">
      <CIcon
        name="logo"
        height="35"
        alt="Logo"
        viewBox="0 0 630.11 174.77"
        class="d-md-down-none mr-auto m-2"
      />
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/">Projetos</CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink>Configuração</CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav>
      <CHeaderNavItem class="px-3">
        <button @click="alterarModoEscuro" class="c-header-nav-btn">
          <CIcon v-if="$store.state.darkMode" name="cil-sun" />
          <CIcon v-else name="cil-moon" />
        </button>
      </CHeaderNavItem>
      <TheHeaderDropdownNotif />
      <TheHeaderDropdownTasks />
      <TheHeaderDropdownMssgs />
      <TheHeaderDropdownAccnt />
      <CHeaderNavItem class="px-3">
        <button in-header class="c-header-nav-btn" @click="$store.commit('toggle', 'asideShow')">
          <CIcon size="lg" name="cil-applications-settings" class="mr-2" />
        </button>
      </CHeaderNavItem>
    </CHeaderNav>

    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
import TheHeaderDropdownNotif from "./TheHeaderDropdownNotif";
import TheHeaderDropdownTasks from "./TheHeaderDropdownTasks";
import TheHeaderDropdownMssgs from "./TheHeaderDropdownMssgs";

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
    TheHeaderDropdownNotif,
    TheHeaderDropdownTasks,
    TheHeaderDropdownMssgs
  },
  methods: {
    alterarModoEscuro: function() {
      this.$store.commit("toggle", "darkMode");
      this.$localStorage.set("modo_escuro", this.$store.state.darkMode);
    }
  }
};
</script>
<style>
div.c-app div.c-sidebar a.c-sidebar-brand {
  background: white;
  border-bottom: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgb(216, 219, 224);
  height: 55px;
}
div.c-dark-theme div.c-sidebar a.c-sidebar-brand {
  background: rgba(0, 0, 21, 0.2);
  border-bottom: none;
}
.c-dark-theme svg g#Camada_1-2 text,
.c-dark-theme svg g#Camada_2-2 text,
.c-dark-theme svg g#Camada_4 polygon {
  fill: rgb(200, 200, 200);
}
</style>
