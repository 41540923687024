<template>
  <div class="c-app" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <TheSidebar v-if="projetoID()" />
    <TheAside />
    <CWrapper>
      <TheHeader />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade">
              <router-view></router-view>
            </transition>
          </CContainer>
        </main>
        <TheFooter />
      </div>
    </CWrapper>
    <CToaster :autohide="3000">
      <template v-for="(mensagem,indexMensagem) in mensagens">
        <CToast
          :key="indexMensagem"
          :show="true"
          header="Aviso"
          :class="mensagem.classe"
        >{{mensagem.mensagem}}</CToast>
      </template>
    </CToaster>
  </div>
</template>

<script>
import TheSidebar from "./TheSidebar";
import TheHeader from "./TheHeader";
import TheFooter from "./TheFooter";
import TheAside from "./TheAside";

export default {
  name: "TheContainer",
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    TheAside
  },
  data() {
    return {
      projetoId: false,
      mensagens: []
    };
  },
  methods: {
    projetoID: function() {
      return this.$route.params.projetoID;
    },
    adicionarMensagem(mensagem) {
      this.mensagens.push(mensagem);
    }
  },
  created() {
    this.$eventoHub.$on("adicionarMensagemAviso", this.adicionarMensagem);
  },
  destroyed() {
    this.$eventoHub.$off("adicionarMensagemAviso", this.adicionarMensagem);
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
