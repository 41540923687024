<template>
  <CFooter :fixed="false">
    <div>
      <a href="http://solidbase.com.br" target="_blank">SolidBase</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}}.</span>
    </div>
    <div class="ml-auto">
      <span class="mr-1" target="_blank">Desenvolvido por</span>
      <a href="http://solidbase.com.br">Omnia Sistemas</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: "TheFooter"
};
</script>
